import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../App.css";
import fetchAddLossReport from "../../redux/thunk/fetchAddLossReport";
import Loading from "../ui/Loading";
import search from "../../assets/search.svg";
import Error from "../ui/Error";
import Success from "../ui/Success";
import axios from "../../redux/thunk/utils/axios";
import fetchUpdateReportLoss from "../../redux/thunk/fetchUpdateReportLoss";
export default function ReportLoss() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [shipDate, setShipDate] = useState("");
  const [notes, setNotes] = useState("");
  const [type, setType] = useState("default");
  const [inputs, setInputs] = useState([
    { sku_id: "", loss_quantity: "", sku_price: "" },
  ]);
  const [notification, setnotification] = useState(false);
  const [notification_msg, setnotification_msg] = useState("");
  const [updateMode, setUpdateMode] = useState(false);
  let navigate = useNavigate();
  const login = useSelector((state) => state.login);
  const reportLossAdd = useSelector((state) => state.reportLossAdd);
  const reportLossUpdate = useSelector((state) => state.reportLossUpdate);
  const dispatch = useDispatch();
  const { userInfo } = login || {};
  const { loading, isError, error, success } = reportLossAdd || {};
  const {
    loading: updateLoading,
    isError: updateIsError,
    error: updateError,
    success: updateSuccess,
  } = reportLossUpdate || {};

  const reset = useCallback(() => {
    setFirstName("");
    setLastName("");
    setOrderNumber("");
    setNotes("");
    setShipDate("");
    setInputs([{ sku_id: "", loss_quantity: "", sku_price: "" }]);
  }, []);
  const submitHandler = (e) => {
    e.preventDefault();
    let loss_report;
    loss_report = {
      customer_first_name: firstName,
      customer_last_name: lastName,
      order_number: orderNumber,
      ship_date: shipDate,
      notes: notes,
      sku_loss: inputs,
    };
    setType("create");
    dispatch(fetchAddLossReport(loss_report));
  };
  const handleAddMore = () => {
    setInputs([...inputs, { sku_id: "", loss_quantity: "", sku_price: "" }]);
  };

  const handleInputChange = (index, field, value) => {
    const newInputs = inputs.map((input, i) =>
      i === index ? { ...input, [field]: value } : input
    );
    setInputs(newInputs);
  };
  const searchHandler = (e) => {
    e.preventDefault();
    setType("search");

    axios
      .get(`api/report/loss_report/${orderNumber}/`, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      })
      .then((res) => {
        const { ship_date, order_number, name, notes, sku_loss } =
          res.data || {};
        setShipDate(ship_date);
        setOrderNumber(order_number);
        const nameParts = name.split(" ");
        if (nameParts.length > 1) {
          setFirstName(nameParts[0]);
          setLastName(nameParts[1]);
        } else {
          setFirstName(nameParts[0]);
          setLastName(""); // In case there's no last name
        }
        setNotes(notes);
        if (Array.isArray(sku_loss)) {
          const formattedInputs = sku_loss.map((item) => ({
            sku_id: item.sku || "",
            loss_quantity: item.loss_quantity || "",
            sku_price: item.unit_cost || "",
          }));
          setInputs(formattedInputs);
          setUpdateMode(true);
        }
      })

      .catch((err) => {
        // console.log(err.response.data)
        setnotification(true);
        if (err?.response?.data.hasOwnProperty("error")) {
          setnotification_msg(err.response.data.error);
        } else {
          setnotification_msg("Unexpected error from api.");
        }
      });
  };
  const updateHandler = (e) => {
    e.preventDefault();
    setType("update");
    let loss_report;
    loss_report = {
      customer_first_name: firstName,
      customer_last_name: lastName,
      order_number: orderNumber,
      ship_date: shipDate,
      notes: notes,
      sku_loss: inputs,
    };
    dispatch(fetchUpdateReportLoss(orderNumber, loss_report));
  };
  console.log(inputs, "This is inputs");
  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
    if (success) {
      reset();
    }
  }, [navigate, userInfo, success, reset]);
  let content;
  content =
    type === "create" ? (
      <div>
        {loading && <Loading />}
        {isError && <Error message={error} />}
        {success && <Success message="Loss Report Create Successfully" />}
      </div>
    ) : type === "update" ? (
      <div>
        {updateLoading && <Loading />}
        {updateIsError && <Error message={updateError} />}
        {updateSuccess && (
          <Success message="Loss Report Updated Successfully" />
        )}
      </div>
    ) : type === "search" ? (
      <div>{notification && <Error message={notification_msg} />}</div>
    ) : (
      ""
    );

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col
          xs={12}
          md={12}
          style={{ textAlign: "center", margin: "20px 0px" }}
        >
          <h1>Report Loss</h1>
        </Col>
        <hr />
        <br />
        <br />
        <Col xs={12} md={8}>
          {notification && <Error message={notification_msg} />}
          <Form onSubmit={updateMode ? updateHandler : submitHandler}>
            <Form.Group
              controlId="first_name"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "20px",
              }}
            >
              <Form.Label>Customer First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Customer First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>
            <br />
            <Form.Group
              controlId="last_name"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "20px",
              }}
            >
              <Form.Label>Customer Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Customer Last Name"
                value={lastName}
                required
                onChange={(e) => setLastName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <br />
            <Form.Group
              controlId="order_number"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "75px",
              }}
            >
              <Form.Label>Order Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Order Number"
                value={orderNumber}
                required
                onChange={(e) => setOrderNumber(e.target.value)}
              ></Form.Control>
              <Image
                src={search}
                alt="search_icon"
                style={{ width: "40px", height: "40px", cursor: "pointer" }}
                onClick={searchHandler}
              />
            </Form.Group>
            <br />
            <Form.Group
              controlId="ship_date"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "82px",
              }}
            >
              <Form.Label>Ship Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Enter Ship Date"
                value={shipDate}
                required
                style={{ fontSize: "18px" }}
                onChange={(e) => setShipDate(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <br />
            <Form.Group
              controlId="ship_date"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "90px",
              }}
            >
              <Form.Label>Notes</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter Your Notes Here"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <br />
            {inputs.map((input, index) => (
              <div key={index} className="d-flex mb-3">
                <Form.Group controlId={`sku_id_${index}`} className="me-3">
                  <Form.Label>SKU ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter SKU ID"
                    value={input.sku_id}
                    required
                    onChange={(e) =>
                      handleInputChange(index, "sku_id", e.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group
                  controlId={`loss_quantity_${index}`}
                  className="me-3"
                >
                  <Form.Label>Loss Quantity</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Loss Quantity"
                    required
                    value={input.loss_quantity}
                    onChange={(e) =>
                      handleInputChange(index, "loss_quantity", e.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group controlId={`sku_price${index}`}>
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Item Price"
                    required
                    value={input.sku_price}
                    onChange={(e) =>
                      handleInputChange(index, "sku_price", e.target.value)
                    }
                  />
                </Form.Group>
              </div>
            ))}
            <Button variant="info" onClick={handleAddMore} className="me-2">
              Add More
            </Button>
            <Button
              type="submit"
              variant={updateMode ? "outline-success" : "outline-primary"}
              style={{ width: "500px", margin: "25px" }}
            >
              {updateMode ? "Update Loss Report" : "Save Loss Report"}
            </Button>
          </Form>
        </Col>
        <br />
        <br />
      </Row>
      {content}
    </Container>
  );
}
